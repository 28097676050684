<template>
  <TTView>
    <VRow>
      <VCol>
        <PhoneForm
          :entity="phone"
          @update:phone="phone.phone = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_PHONE } from '../../components/authentication/common';
import PhoneForm from '../../components/authentication/PhoneForm.vue';

export default {
  name: 'PhonesCreate',

  components: {
    PhoneForm,
  },

  data() {
    return {
      loading: false,
      phone: { ...DEFAULT_PHONE },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const payload = {
          id: this.$route.params.userId,
          phone: this.normalizePhone(this.phone.phone),
        };

        await this.$di.api.Authentication.NewPhoneWithoutVerification(payload);
        this.$router.go(-1);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
    normalizePhone(phone) {
      return phone.replace(/\D/g, '');
    },
  },
};
</script>
