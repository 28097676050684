<template>
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm ref="phoneCreateForm">
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                v-mask="'###############'"
                type="tel"
                placeholder="79000000000"
                :value="entity.phone"
                label="Телефон"
                :rules="rules"
                @input="$emit('update:phone', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_PHONE } from './common';

export default {
  name: 'PhoneForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_PHONE }),
    },
  },

  data() {
    return {
      rules: [
        (text) => Boolean(text) || 'Это поле обязательное',
      ],
    };
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание телефона';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование телефона';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      if (this.$refs.phoneCreateForm.validate()) {
        this.$emit('submit', this.entity);
      }
    },
  },
};
</script>
